<template>
  <v-list-item @click="href">
    <v-list-item-title>
      <div class="d-flex align-center py-3">
        <div class="mr-3">
          <v-badge
              bordered
              top
              color="primary"
              dot
              offset-x="10"
              offset-y="10">
            <v-avatar
                :color="getColor()"
                size="48"
                class="white--text">
              {{ item.name | initials }}
            </v-avatar>
          </v-badge>
        </div>
        <div class="mx-3">
          <h5 class="font-weight-medium">{{ item.name }}</h5>
          <span
              class="text--secondary descpart d-block truncate-text subtitle-2">
                                    {{ item.email }}
                                </span>
        </div>
        <div class="ml-auto mr-0" v-if="user.roles == 'ADMIN' || user.roles == 'COPARMEX'">
          <v-btn icon @click="openDialog">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </div>
    </v-list-item-title>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h6 headline">
          ¿Desea eliminar este comentario?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" @click="deleteAction">
            Proceder
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
import dashboardService from '@/providers/DashboardService';

export default {
  name: "ItemContact",
  props: ["item"],
  data(){
    return {
      user: {},
      href() {
        return undefined;
      },
      dialog: false,
    }
  },
  filters: {
    initials(value) {
      const name = value.split(' ');
      return `${name[0].charAt(0).toUpperCase()}${name[1] ? name[1].charAt(0).toUpperCase() : ''}`;
    },
    trimText(value) {
      const name = value.trim();
      return name;
    }
  },
  methods: {
    openDialog(){
      this.dialog = true;
    },
    closeDialog(){
      this.dialog = false;
    },
    genRandomIndex (length) {
      return Math.ceil(Math.random() * (length - 1))
    },
    deleteAction(){
      dashboardService.delete(this.item).then(resp=>{
        if(resp.data.success){
          this.$emit('reload');
        }
      }).catch(err=>console.log(err));
    },
    getColor() {
      let colors = [
        "info",
        "success",
        "primary",
        "teal",
        "deep-purple accent2",
        "red lighten-1",
        "pink darken-2",
        "grey darken-2",
        "brown",
        "blue-grey darken-3",
        "red darken-3",
        "green"
      ];
      const colorsLength = colors.length;
      let colorsel = this.genRandomIndex(colorsLength);
      return colors[colorsel];
    },
  },
  mounted() {
    this.user = this.$store.getters.getUser;
  }
}
</script>

<style scoped>

</style>